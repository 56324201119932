export default [
  {
    header: 'Advertiser App Menu',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Influencer',
    route: 'find-influencer',
    icon: 'UsersIcon',
  },
  {
    title: 'Campaign',
    route: 'campaign',
    icon: 'UsersIcon',
  },
  {
    title: 'Setting',
    children: [
      {
        title: 'Informasi Pengguna',
        route: 'user-information',
      },
      {
        title: 'Informasi Bisnis',
        route: 'business-information',
      }
    ],
  },
  {
    title: 'Billing',
    children: [
      {
        title: 'Daftar Invoice',
        route: 'invoice'
      },
      {
        title: 'Riwayat Transaksi',
        route:'transaction-history'
      },
      {
        title: 'Top-Up Kredit Iklan',
        route: 'top-up-credit'
      },
      {
        title: 'Pengajuan Termin',
        route: 'termin'
      }
    ]
  }
]