<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown :notifications="notifications" :limit="limit" :totalMessage="totalMessage" :totalUnread="totalUnread" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'

import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import axios from '@axios'
import { EventBus } from "@/eventBus.js";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    
    
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      limit: 10,
      notifications: null
    }
  },
  beforeMount() {
    console.log('GET Notif here')
    let _ = this
    _.appendNotif()
  },
  mounted() {
    EventBus.$on('change_limit', (i) => {
      this.limit = i
      this.appendNotif()
    })
  },
  methods: {
    appendNotif() {
      let _ = this
      axios.get('/account/notifications?limit=' + _.limit)
        .then(response => {
          _.limit = response.data.data.perPage
          _.totalMessage = response.data.data.totalData
          _.totalUnread = response.data.data.totalUnread
          let notif = response.data.data.data.map(notif => {
            let pageId = null
            if ( notif.type == 'campaign' ) {
              pageId = notif.campaignID
            }

            return {
                id: notif.id,
                title: notif.title,
                avatar: 'MD',
                subtitle: notif.body,
                type: 'light-danger',
                page: notif.type,
                pageId: pageId,
                unread: notif.unread,
                createdAt_cast: notif.createdAt_cast
              }
          })

          _.notifications = notif
        })
    },
    chLimit($e, l) {
      console.log('Change Limit: ' + l)
    }
  }
}
</script>
