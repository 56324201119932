<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalUnread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ totalUnread }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <h5 v-if="!notifications">Please wait...</h5>
      <!-- Account Notification -->
      <b-link
        v-for="(notification, i) in notifications"
        :key="i"
        @click="gotoPage(notification.id ,notification.page, notification.pageId)"
      >
        <b-media>
          <template #aside>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
            <span class="float-right" v-if="notification.unread == true">
               <b-badge variant="light-primary">
                <feather-icon
                  icon=""
                  class="mr-25"
                />
                <span>Baru</span>
              </b-badge>
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
          <small class="float-right mt-1">{{notification.createdAt_cast}}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li v-show="limit < totalMessage">
      <p class="float-right mt-1 mr-2" @click="changeLimit()" style="cursor:pointer">See More</p>
    </li>
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      :disabled="notifications.length == 0"
      @click="readAll"
    >Tandai semua sudah di baca</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { EventBus } from "@/eventBus.js";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  props: ['notifications', 'limit', 'totalMessage', 'totalUnread'],
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false
      }
    }
  },
  methods: {
    appendNotif() {
      let _ = this
      axios.get('/account/notifications')
        .then(response => {
          console.log(response.data.data)
          let notif = response.data.data.data.map(notif => {
            let pageId = null
            if ( notif.type == 'campaign' ) {
              pageId = notif.campaignID
            }

            return {
                id: notif.id,
                title: notif.title,
                avatar: 'MD',
                subtitle: notif.body,
                type: 'light-danger',
                page: notif.type,
                pageId: pageId
              }
          })

          _.notifications = notif
        })
    },
    gotoPage(id, page, pageId) {
      axios.put('/digirans/notifications/'+id+'/read')
        .then(() => {
          console.log(page)
          if ( page == 'termin' ) {
            window.location = '/billing/pengajuan-termin'
          } else if ( page == 'campaign' ) {
            window.location = '/campaign/'+pageId+'/detail'
          } else if ( page == 'verified' ) {
            
          } else if ( page == 'topup' ) {
            window.location = '/billing/top-up-credit'
          } else if ( page == 'invoice' ) {
            window.location = '/billing/invoice-list'
          }
        })
        .catch(err => console.log(err.response))
    },
    readAll() {
      console.log('read all')
    },
    changeLimit() {
      EventBus.$emit("change_limit", this.limit + 2);
    }
  }
}
</script>

<style>

</style>
